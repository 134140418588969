<!-- 真人视讯 -->
<template>
  <div class="content">
    <div class="mod-live-casino">
      <div class="mod-banner" style="height: 460px">
        <!--焦点图-->
        <Banner />
        <!--end 焦点图-->
      </div>
      <ul class="l-c-platform">
        <li
          class="betb2b-item"
          v-for="sport in lists"
          :key="sport.id"
          @click="_getToken(sport, 2)"
        >
          <img :src="systemImgHost + sport.imgUrl" />
          <div class="btn"></div>
        </li>
      </ul>

      <div class="live-text page-text">
        <!-- Sports Lobby within <div class="sport-text page-text"> -->
        <div class="layout" :class="{ 'layout-height': moreShow }">
          <h1>Live casino games with FUN88</h1>
          <p>
            Want the buzz and excitement of being on a live casino floor – a
            real gaming table, a real live dealer, and all the action in
            real-time?
          </p>
          <p>
            When you play online with Fun88's Live Casino – you will enjoy the
            gaming excitement of a live casino floor! Available easily on your
            screen – be it your desktop or a mobile phone, Fun88 is the best
            online site to play live casino games in India.
          </p>
          <p>
            Bet on having the time of your life as you throw down your chips,
            watch the roulette ball bobble and hold a winning flush of cards
            with Teen Patti as you enjoy the thrills of a live casino online.
            Whether you are a seasoned card player or a curious newbie, play
            casino games online as you enjoy the dynamic energy, the seamless
            gaming and the classy playground set up with over 200 games just for
            you by Fun88’s industry-leading team.
          </p>
          <p>
            Powered by Evolution Gaming, Superspade, Ezugi, Microgaming and
            Playtech, Fun88's live casino boasts of gaming tables that offer
            Live Roulette, Blackjack and Baccarat along with Andar Bahar and
            Teen Patti. As the personalized gaming experience unfolds on the
            floor of our live casino, dealers interact with cards and tables,
            giving you the real-game thrill. Since the videos of the games are
            streamed live and transmitted in real-time, you can be sure that
            gaming is fair, transparent and randomized.
          </p>
          <h2>Best live casino games- play with real money!</h2>
          <h2 style="font-size: 20px; padding: 0; margin-top: 0">
            Andar bahar game
          </h2>
          <p>
            One of India’s favourite card games,
            <router-link to="/live" target="_blank">Andar Bahar</router-link>
            , is played by almost everyone – old or young, easy to play anywhere
            and is a festival must-do! Simple as you chose one side – Andar or
            Bahar, you don’t need to be an expert to win this game of cards. An
            excellent pastime, this popular game of cards in India is also known
            as Maang Patta or Katti. What makes Andar Bahar a super hit amongst
            casino players is that it offers 50/50 odds, allowing everyone a
            fair winning chance. Play online Andar Bahar for real cash on Fun88!
          </p>
          <h2 style="font-size: 20px; padding: 0; margin-top: 0">
            Online teen patti
          </h2>
          <p>
            Known as flush or flash, the three-card game of Teen Patti is a
            traditional
            <router-link to="poker?id=" target="_blank">Poker game</router-link>
            played to invite good luck! Now, you don’t have to wait for the
            festivities to win a hand, play with a live dealer and real players!
            Claim your starting
            <router-link to="/promo" target="_blank">
              welcome bonus
            </router-link>
            by playing casino card games on Fun88, the best online gaming site.
          </p>
          <h2 style="font-size: 20px; padding: 0; margin-top: 0">
            Live roulette
          </h2>
          <p>
            One of the most popular live casino games, Roulette’s lure has never
            faded even after two centuries of its existence. A real-life wheel,
            understanding the Martingale Strategy and Stochastic Processes,
            working your skill, intelligence and winning strategies as you play
            live casino will bring its thrill. Understand the ins and outs of
            roulette as you watch the action in real-time with live dealers and
            take your game to the next level.
          </p>
          <h2 style="font-size: 20px; padding: 0; margin-top: 0">
            Live blackjack
          </h2>
          <p>
            The staple of every live casino, Blackjack on Fun88 is even more
            exciting with a live dealer. As you play live casino online,
            Blackjack is bound to keep you hooked by its simple stimulating
            premise -- better the odds to beat the dealer or fellow dealer by
            getting your cards as close to a 21. Also, Fun88’s live stream
            offers you exciting table action as you play against others and
            enjoy the live-action with live dealers and live winnings!
          </p>
          <h2 style="font-size: 20px; padding: 0; margin-top: 0">
            Live baccarat
          </h2>
          <p>
            Strategies, various methodologies and intriguing rules lend this
            psychological card game a definite edge. Also known as Punto Banco,
            Baccarat is a relatively simple game of comparing cards and perfect
            for newbies! Fun88’s Baccarat in a live casino online format works
            by giving you a live casino feel courtesy of our live dealers and
            the interactions with them and other players seated at the table.
            Make the most of Baccarat and the ultimate table game action with
            our optimized and real-life stream settings!
          </p>
          <p>
            <router-link to="/home" target="_blank">Fun88</router-link> is a top
            online casino in India, which lets you make real money by playing a
            number of exciting games. Being the best online casino in India, we
            offer you betting tips, enabling you to experience the thrill and
            excitement of games like <b>online Teen Patti</b>, Andar Bahar, etc.
            On Fun88’s live platform, you can enjoy the best views, interactive
            entertainment and optimized playing experience.
          </p>
          <p>
            An exciting online casino bonus awaits you when you
            <router-link to="/author?type=1" target="_blank"
              >sign up with us!</router-link
            >
          </p>
          <div class="mt-30">
            <h3>Play online teen patti on FUN88</h3>

            <p>
              Fun88 offers the best online Teen Patti experience for a diverse
              set of players. Just like in a traditional brick and mortar
              casino, on Fun88’s online casino, you’ll see a real dealer with
              real cards. While we offer an online casino experience, it’s
              really like going to a real casino.
            </p>

            <p>
              At Fun88’s website and mobile app, you get the easiest display to
              follow a Teen Patti game. Just follow the screen, and it will show
              you the highest hand from your three cards, allowing you to make
              quick decisions.
            </p>

            <p>
              At the same time, it will also show you the dealer’s cards so that
              you know if you’ve already won. Such an easy display allows for
              100% transparency while enjoying a Teen Patti online experience.
            </p>
          </div>
          <h3 style="font-size: 20px; padding: 0">
            How is online teen patti played?
          </h3>
          <p>
            Just like how we play Teen Patti with real cards, a single deck of
            52 cards is used even in an online version of the game. Jokers are
            discarded from the card pack. Then, the game progresses, and you can
            see the dealer distributing three cards each per player. The dealer
            distributes cards in an anti-clockwise direction and they are kept
            face down.
          </p>
          <p>
            The player who wins a hand becomes the dealer in the next hand. As
            opposed to other card games, Teen Patti allows players to bet
            without any limit on as many rounds as they wish. In the end, the
            two players, who survive through the game, have to show the cards in
            their hands. The player with the highest-ranking hand wins the game.
            If all the other players fold before this stage, the only player who
            remains wins the game.
          </p>
          <h3 style="font-size: 20px; padding: 0">
            What is a live dealer game?
          </h3>
          <p>
            The concept of a live dealer game has gained significant attention
            in recent years. So, what are “live dealer games” after all? So far,
            people had heard of either traditional brick-and-mortar casinos or
            playing some online casino games. But live casino games have gone a
            step further. Live casino games allow you to go up against a real
            dealer from the comforts of your home or anywhere else. So, you are
            playing against a real dealer without physically going to a casino.
            Ain’t it interesting?
          </p>
          <p>
            You can play live dealer games at Fun88 round-the-clock by just
            accessing our platform through your desktop or mobile phone. Unlike
            traditional brick-and-mortar casinos, there is no waiting time and
            no queues. Just
            <router-link to="/author?type=1" target="_blank"
              >log in to Fun88 </router-link
            >and start playing in an instant.
          </p>
          <div class="mt-30">
            <h3>Advantages of playing casino live</h3>

            <p>
              <b style="color: #00a6ff; font-size: 20px"
                >Play anytime at any place –
              </b>
              The best thing about playing casino live at Fun88 is you can play
              anytime and anywhere. All you need to do is register on our
              website or download our app and you’ll get the casino action live
              at your fingertips. Isn’t it a lot better than going to a
              traditional casino?
            </p>

            <p>
              <b style="color: #00a6ff; font-size: 20px"
                >Cutting-edge technologies and systems –
              </b>
              Fun88 uses technologies and solutions from top names like
              Evolution Gaming, Superspade, Ezugi, Microgaming, and Playtech to
              provide a state-of-art
              <router-link to="/live" target="_blank">live casino</router-link>
              gaming experience to players.
            </p>

            <p>
              <b style="color: #00a6ff; font-size: 20px"
                >Live chat feature –
              </b>
              If you ever get stuck while playing, you can use our “Live Chat”
              feature, wherein our customer care executives will help you deal
              with the problem so that you can start playing casino games asap.
            </p>

            <p>
              <b style="color: #00a6ff; font-size: 20px">Fair games – </b> You
              can rest assured that Fun88 lives up to the highest standards of
              fairness when offering live casino games to players. Each one of
              you has a fair chance of winning at our platform and all our games
              are totally fair, transparent, and randomized. Besides, you can
              see for yourself how the games are progressing, as they are played
              in front of you on your computer or laptop screen.
            </p>

            <p>
              <b style="color: #00a6ff; font-size: 20px"
                >Instant access to live tables –
              </b>
              Get instant access to live tables to play your favourite games
              like Andar Bahar, Teen Patti, Live Roulette, Live Blackjack, and
              Live Baccarat at Fun88. You can see real dealers and real tables
              in an instant, as you play these games on our site or app.
            </p>
          </div>
          <div class="mt-30">
            <h3>Best live casino games- play with real money!</h3>
            <p>
              From online Teen Patti to Blackjack, experience the fun, thrill
              and big wins with Fun88! Choose bonus promotions such as
              First-Time Deposits, Reload Bonus, and much more on our Live
              Casino games!
            </p>
            <img
              src="../../assets/images/in/livegames.png"
              style="display: block; margin: 0.5rem auto 2em; width: 100%"
            />
          </div>
          <h3 style="font-size: 20px">Responsible gaming at FUN88</h3>
          <p>
            We understand the thrill and excitement of playing Live Roulette or
            Teen Patti, but we also know that excessive gambling can be
            self-destructive for a lot of people. That said, what is “excessive
            gambling” varies from player to player. Therefore, as a player, you
            need to ensure that you aren’t taking undue risks. If you still have
            trouble understanding what responsible gaming is, do reach out to
            our staff using
            <a href="#" target="_blank">Contact Us</a>, and they will help you
            out.
          </p>
          <h3>Tips to encourage responsible gaming:</h3>
          <p>
            <b style="color: #00a6ff; font-size: 20px"
              >Set a betting limit and make sure that you stick to it –
            </b>
            While playing online casino games or any kind of gambling, also keep
            a betting limit. This means you should have a limit in terms of how
            much you’d like to lose per day or week. If you know the maximum
            loss you can incur, you’ll be safe.
          </p>
          <p>
            <b style="color: #00a6ff; font-size: 20px"
              >Play for fun, not for money –
            </b>
            The primary objective of online gambling should be to have fun. If
            in the course of having fun, you make money, it’s great. However,
            you shouldn’t keep making money as an objective for two reasons.
            First, it’s tough to make money regularly while playing online
            games. Second, if you get obsessed with making money, you won’t be
            able to have fun, which is why you started playing online casino
            games in the first place.
          </p>
          <p>
            <b style="color: #00a6ff; font-size: 20px"
              >Always be sober while gaming –
            </b>
            Although some people do it, we'd never advise you to drink alcohol
            or take any kind of substance which may affect your ability to make
            a good decision while playing online casino games. It’s best to be
            sober to enjoy online gaming.
          </p>
          <p>
            <b style="color: #00a6ff; font-size: 20px"
              >Don’t be afraid to take a break –
            </b>
            Often, players become obsessive with online gaming, which affects
            their state of mind. Therefore, it’s a good idea to take a break
            from gaming for a while. Pick up another hobby, travel, or meet
            people, and then come back to the online casino action.
          </p>
          <div class="mt-50">
            <div class="row">
              <div class="col col-lg">
                <h3 class="mt-0">
                  Download FUN88’s app and play casino games on your mobile
                </h3>
                <p>
                  Play online casino games like Andar Bahar, Teen Patti, Live
                  Roulette, Live Blackjack, and Live Baccarat from anywhere by
                  downloading Fun88’s mobile app, available in both Android and
                  iOS variants.
                </p>
                <p>
                  Go up against a live dealer and have the excitement and thrill
                  of going to a traditional casino at your fingertips thanks to
                  the Fun88’s app.
                </p>
              </div>
              <div class="col col-lg mt-10">
                <p style="text-align: center; font-size: 20px">
                  To download, scan the QR code given below from your mobile or
                  open the
                  <a :href="downloadUrl" target="_blank" style="color: #00a6ff"
                    >app download link</a
                  >.
                </p>
                <div class="row mt-10">
                  <div class="col col-lg">
                    <div
                      id="qrcode"
                      style="display: block; margin: 0 auto; width: 158px"
                    ></div>
                  </div>
                  <div class="col col-lg">
                    <p
                      style="
                        margin: 0;
                        padding: 1em 0 0;
                        font-size: 28px;
                        font-weight: bold;
                        text-align: center;
                        line-height: 33px;
                      "
                    >
                      FUN88 main app
                    </p>
                    <p
                      style="
                        margin: 0;
                        padding: 0;
                        font-size: 22px;
                        font-weight: 100;
                        text-align: center;
                        line-height: 33px;
                      "
                    >
                      Download the app now
                    </p>
                    <p
                      style="
                        margin-bottom: 2em;
                        padding: 0;
                        color: #00a6ff;
                        font-size: 20px;
                        text-align: center;
                        line-height: 33px;
                      "
                    >
                      <a
                        :href="downloadUrl"
                        target="_blank"
                        style="color: #00a6ff; line-height: 33px"
                        >{{ downloadUrl }}</a
                      >
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-50">
            <h3>Casino betting tips from FUN88</h3>
            <p>
              <b style="color: #00a6ff; font-size: 20px"
                >Don’t let gambling become an addiction –
              </b>
              There’s a fine line between being interested in an activity and
              getting obsessed with it. When it comes to online casinos, we’ve
              often seen that players develop an unhealthy obsession with it,
              which impacts their lives. Always remember that the key motto of
              playing casino is to have fun, not to let it adversely impact your
              lives in any way.
            </p>

            <p>
              <b style="color: #00a6ff; font-size: 20px"
                >Know how much to lose –
              </b>
              A lot of regular players keep aside a certain amount of money for
              betting every month. This is the sum which they can afford to
              lose. Even if they lose this entire sum while gambling, their
              overall financial condition isn’t affected. Always know how much
              you can lose while playing online casino games and keep this limit
              rather low so that it doesn’t affect your finances.
            </p>

            <p>
              <b style="color: #00a6ff; font-size: 20px"
                >Research for the best online casinos –
              </b>
              Before starting to bet on online casinos, always do a thorough
              analysis of various online casino platforms to compare their
              charges, features, website/app interface, payment modes, and most
              importantly, promotional offers, including bonuses and lucky
              draws.
            </p>

            <p>
              <b style="color: #00a6ff; font-size: 20px"
                >Check out the terms and conditions of bonuses and other offers
                –
              </b>
              Various offers and bonuses offered by online casinos offer a good
              opportunity for bettors to make money. That said, you should go
              through all the terms and conditions of such offers. If you have
              any doubts, you can always check with the customer care of a
              casino platform.
            </p>

            <p>
              <b style="color: #00a6ff; font-size: 20px"
                >Encash your winnings regularly –
              </b>
              We’ve seen that when players hit a winning streak, they start
              betting even more, thinking that they will win a lot more money.
              This is a risky strategy because unless you’ve cashed your
              winning, they aren’t entirely yours. Therefore, you should always
              encash your winnings regularly and don’t get over-excited by a
              winning streak.
            </p>
          </div>
          <div class="mt-50">
            <h3>Why you should trust our live online casinos</h3>
            <div class="row trust-casinno-icons-row">
              <div class="col col-lg">
                <img
                  src="../../assets/images/in/live.png"
                  style="display: block; margin: 2em auto; width: 90px"
                />
                <div
                  style="
                    text-align: center;
                    font-size: 20px;
                    font-weight: bold;
                    color: #000;
                  "
                >
                  Industry-leading certification
                </div>
                <p style="padding: 1em 1em; text-align: center">
                  <span style="color: #00a6ff"
                    >Fun88 has a certification by</span
                  >
                  E Gambling Montenegro, which is a reputed solutions provider
                  to gaming operators that helps them to acquire licenses. This
                  is one of the leading certifications in the online casino
                  industry, and it shows that Fun88 is a safe and secure
                  platform for live casino games.
                </p>
              </div>
              <div class="col col-lg">
                <img
                  src="../../assets/images/in/live1.png"
                  style="display: block; margin: 2em auto; width: 90px"
                />
                <div
                  style="
                    text-align: center;
                    font-size: 20px;
                    font-weight: bold;
                    color: #000;
                  "
                >
                  Safe payment modes
                </div>
                <p style="padding: 1em 1em; text-align: center">
                  At Fun88, you have an array of safe payment modes to deposit
                  and withdraw your funds. Based on your convenience, you can
                  pick from these payment methods, which Fun88 supports,
                  Netbanking, UPI, G Pay, VISA, MasterCard, Ecopayz, Astropay,
                  Cryptocurrency, and Sticpay. These modes have set industry
                  standards when it comes to the safety and security of funds.
                  Besides, you can deposit in Indian Rupee (INR), so you don’t
                  even have the hassle of converting your money into a foreign
                  currency to play live casino games at Fun88.
                </p>
              </div>
              <div class="col col-lg">
                <img
                  src="../../assets/images/in/live2.png"
                  style="display: block; margin: 2em auto; width: 90px"
                />
                <div
                  style="
                    text-align: center;
                    font-size: 20px;
                    font-weight: bold;
                    color: #000;
                  "
                >
                  Fair gaming practices
                </div>
                <p style="padding: 1em 1em; text-align: center">
                  As we know that trust is the most important factor in the
                  online gaming space, we strive to maintain the highest
                  standards of fairness and transparency while offering you live
                  casino games. Remain assured that there are no hidden charges
                  or rules. You can see the real gaming table on your screen and
                  all the rules are made known to you to ensure that you have a
                  fair chance of winning.
                </p>
              </div>
              <div class="col col-lg">
                <img
                  src="../../assets/images/in/live3.png"
                  style="display: block; margin: 2em auto; width: 90px"
                />
                <div
                  style="
                    text-align: center;
                    font-size: 20px;
                    font-weight: bold;
                    color: #000;
                  "
                >
                  24 / 7 customer care
                </div>
                <p style="padding: 1em 1em; text-align: center">
                  We have 24 / 7 customer care which is accessible through
                  Whatsapp, Telegram, Email, and Phone to help you deal with any
                  problem you encounter while playing online casino games on our
                  platform. Our executives are trained to help you find a quick
                  solution to meet your expectations. Just visit live casino
                  this
                  <a href="#" target="_blank">link</a>
                  and learn how to reach out to Fun88’s customer care.
                </p>
              </div>
              <div class="col col-lg">
                <img
                  src="../../assets/images/in/live4.png"
                  style="display: block; margin: 2em auto; height: 90px"
                />
                <div
                  style="
                    text-align: center;
                    font-size: 20px;
                    font-weight: bold;
                    color: #000;
                  "
                >
                  Secure website and app
                </div>
                <p style="padding: 1em 1em; text-align: center">
                  A secure website and app are paramount to offering a secure
                  gaming experience to players at Fun88. Remain assured that
                  neither our site nor app stores any personal information. So,
                  you don’t face any threat of data leakages at Fu88.
                </p>
              </div>
            </div>
          </div>
          <div class="mt-50">
            <h3>Find the most exciting online casino games on FUN88</h3>
            <div class="row casino-games-row">
              <div class="bg-white col col-lg">
                <img
                  src="../../assets/images/in/Slots.png"
                  style="display: block; margin: 2em auto; width: 90px"
                />
                <div
                  style="
                    text-align: center;
                    font-size: 20px;
                    font-weight: bold;
                    color: #000;
                  "
                >
                  Online Slots
                </div>
                <p style="padding: 1em 1em">
                  Fun88 offers several betting opportunities to bet on India’s
                  favourite sport - cricket. Be it ICC tournaments or bilateral
                  series, if a cricket match is happening, you’ll certainly find
                  a betting option on our website.
                </p>
              </div>
              <div class="bg-white col col-lg">
                <img
                  src="../../assets/images/in/Slots1.png"
                  style="display: block; margin: 2em auto; width: 90px"
                />
                <div
                  style="
                    text-align: center;
                    font-size: 20px;
                    font-weight: bold;
                    color: #000;
                  "
                >
                  Jackpot Slots
                </div>
                <p style="padding: 1em 1em; text-align: center">
                  Want big stakes, bigger excitement and opportunities to win?
                  If you’re looking for this kind of online casino action, then
                  you’d certainly enjoy playing jackpot slots on Fun88. In this
                  category, earnings build with every player, and finally, one
                  lucky player wins the grand prize. Our forever favourites like
                  3-reel Cash Splash and 5-reel Fruit Fiesta will have you
                  hooked to your screen in no time.
                </p>
              </div>
              <div class="bg-white col col-lg">
                <img
                  src="../../assets/images/in/Slots2.png"
                  style="display: block; margin: 2em auto; width: 90px"
                />
                <div
                  style="
                    text-align: center;
                    font-size: 20px;
                    font-weight: bold;
                    color: #000;
                  "
                >
                  Online Table Games
                </div>
                <p style="padding: 1em 1em; text-align: center">
                  With our amazing range of table games, you can certainly
                  indulge your lucky chance streak! Play Live Roulette,
                  Baccarat, Craps, or Blackjack with our expert casino tips
                  which will help you make real money!
                </p>
              </div>
            </div>
          </div>
          <div class="mt-50">
            <h3>Frequently asked questions (FAQs) about live casino</h3>
            <div class="faq mt-20">
              <details open="">
                <summary>Is live casino safe?</summary>
                <div class="faq__content">
                  <p>
                    It all depends upon the safety of the site providing live
                    casino games. As a player, how would you know whether a site
                    is safe or not? The first thing to check is whether it is
                    licensed by a reputable institution. For example, Fun88 is
                    certified by E Gambling Montenegro, a reputed solutions
                    provider to gaming operators to help them acquire licenses.
                    Such certification shows that Fun88 is an absolutely safe
                    platform to play live casino games.
                  </p>
                </div>
              </details>
              <details>
                <summary>How do I play live casino on Fun88?</summary>
                <div class="faq__content">
                  <p>
                    All you have to do is register on Fun88’s website by
                    clicking
                    <router-link to="/author?type=1" target="_blank"
                      >here</router-link
                    >
                    or
                    <router-link to="/mobile-app" target="_blank"
                      >download our app</router-link
                    >, which is available in both Android and iOS variants.
                    Having done that, you just have to go to the “Live Casino”
                    tab of our website and you’ll be able to play all sorts of
                    casino games live.
                  </p>
                </div>
              </details>
              <details>
                <summary>What are the most popular live casino games?</summary>
                <div class="faq__content">
                  <p>
                    Some of the most popular Live Casino games are Andar Bahar,
                    Teen Patti, Roulette, Blackjack, and Baccarat. You can play
                    all these games on Fun88's platform.
                  </p>
                </div>
              </details>
              <details>
                <summary>
                  Do I have to pay taxes on my online casino winnings?
                </summary>
                <div class="faq__content">
                  <p>
                    Yes, you do have to pay taxes on your online casino
                    winnings. Under the Indian tax laws, a taxpayer has to show
                    “Income from Gambling” as a part of “Income from Other
                    Sources” while filing a tax return. Income from gambling is
                    taxed at a flat rate of 30% plus a 4% cess, which amounts to
                    31.2% overall. That said, tax laws are subject to change
                    from time to time. Therefore, you must always consult a tax
                    expert while filing a return.
                  </p>
                </div>
              </details>
            </div>
          </div>
        </div>
        <div @click="moreChange" class="read-more">READ MORE</div>
      </div>
    </div>
  </div>
</template>
<script>
import { _getToken } from "../../core/comp/common";
import Banner from "../../core/components/Banner.vue";
import { _createQRcode, _downloadApp } from "../../core/utils/utils";
import { mapGetters } from "vuex";

export default {
  components: {
    Banner,
  },
  data() {
    return {
      moreShow: true,
      downloadUrl: '',
    };
  },
  computed: {
    lists() {
      // 二级导航
      return this.gameMenu.arr_zb || [];
    },
    ...mapGetters([
      "gameMenu", // 游戏分类
      "systemImgHost",
    ]),
  },
  mounted() {
    // this.splitList()
    // this.curItem = this.lists[0]
    // this.curId = this.lists[0].id
    this.qrcodeChange();
  },
  methods: {
    qrcodeChange() {
      this.downloadUrl = _downloadApp();
      _createQRcode({
        id: "qrcode",
        content: this.downloadUrl,
        width: 158,
        height: 158,
      });
    },
    _getToken,
    // switchTab(item) {
    //     this.curItem = item
    //     this.curId = item.id
    // },
    // // 数组切割 列表 每组几个
    // splitList() {
    //     for (let i = 0; i < this.lists.length; i += 5) {
    //         this.newList.push(this.lists.slice(i, i + 5));
    //     }
    // },
    moreChange() {
      this.moreShow = !this.moreShow;
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  background-color: #fff;
  .mod-banner {
    width: 100%;
    height: 460px;
    position: relative;
    overflow: hidden;
    margin: 0 auto;
    z-index: 5;
  }
  .slide-wrap {
    position: relative;
    text-align: center;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  .l-c-platform {
    max-width: 1550px;
    margin: 25px auto 0;
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    li {
      box-sizing: border-box;
      position: relative;
      width: 25%;
      height: 250px;
      padding: 5px;
      img {
        width: 100%;
        height: 100%;
      }
      .btn {
        background: url(../../assets/images/in/play_now_btn.png) no-repeat
          center center / contain;
        display: block;
        height: 60px;
        width: 150px;
        text-align: center;
        position: absolute;
        bottom: 6%;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
  .page-text {
    font-size: 16px;
  }
  .live-text {
    padding: 60px 0 30px;
    max-width: 1400px;
    margin: 0 auto;
    .layout {
      max-width: 1200px;
      width: 100%;
      overflow: hidden;
      margin: 0 auto;
    }
    .layout-height {
      height: 300px;
    }
    p {
      line-height: 24px;
      padding-bottom: 15px;
      margin: 0;
      font-size: 16px;
      color: #404040;
    }
    a {
      color: #00a6ff;
      text-decoration: none;
      font-weight: bold;
    }
    .read-more {
      width: 241px;
      height: 41px;
      margin: 30px auto 0;
      box-sizing: border-box;
      border-radius: 8px;
      border: 1px solid #00a6ff;
      line-height: 41px;
      text-align: center;
      color: #00a6ff;
      font-size: 16px;
      font-weight: bold;
      cursor: pointer;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-weight: 600;
      color: #00a6ff;
    }
  }
}

.page-text h1,
.page-text h2,
.page-text h3,
.page-text h4,
.page-text h5,
.page-text h6 {
  font-weight: 600;
  color: #00a6ff;
}

.page-text h2,
.page-text h3,
.page-text h4,
.page-text h5,
.page-text h6 {
  text-align: left;
}

.page-text,
.agency-content {
  font-size: 16px;
}

.page-text p,
.agency-content p {
  padding-bottom: 15px;
  font-size: 16px;
  color: #404040;
}

.page-text b {
  color: #00a6ff;
}

.page-text h1 {
  margin-bottom: 10px;
}

.page-text h2 {
  margin-bottom: 10px;
  margin-top: 10px;
}

.page-text h3 {
  margin-bottom: 10px;
  margin-top: 10px;
}

.page-text h4 {
  margin-bottom: 10px;
  margin-top: 10px;
}

.page-text h5 {
  margin-bottom: 10px;
  margin-top: 10px;
}

.mt-0 {
  margin-top: 0px;
}

.mb-0 {
  margin-bottom: 0px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mt-10 {
  margin-top: 10px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mt-20 {
  margin-top: 20px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mt-30 {
  margin-top: 30px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mt-40 {
  margin-top: 40px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mt-50 {
  margin-top: 50px;
}

.button:hover {
  opacity: 0.9;
}

.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: 30px;
}

.bg-white {
  box-shadow: 0px 0px 15px #00a6ff38;
  margin: 1em;
  background-color: white;
}

.col-lg {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}

.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}

.row [class*="col"] {
  position: relative;
}
/* FAQ */

.faq details summary {
  color: #00a6ff;
  padding: 18px 7px;
  outline: none;
  text-align: left;
  cursor: pointer;
  border: 1px solid #e3e3e3;
  position: relative;
  list-style: none;
  font-size: 20px;
  font-weight: 500;
}

.faq details summary:last-child {
  border-bottom: 1px solid #e3e3e3;
}

.faq details summary::after {
  position: absolute;
  background: url(../../assets/images/in/right.png) no-repeat scroll;
  right: 20px;
  width: 26px;
  height: 26px;
  content: " ";
}

.faq details summary::marker {
  display: none;
}

.faq details[open] {
  background-color: #f8f8f8;
}

.faq details[open] summary ~ * {
  -webkit-animation: sweep 0.5s ease-in-out;
  animation: sweep 0.5s ease-in-out;
}

.faq details[open] summary {
  background-color: #f8f8f8;
  padding: 18px 15px;
  border-bottom: 1px solid #f8f8f8;
}

.faq details[open] summary::after {
  position: absolute;
  background: url(../../assets/images/in/down.png) no-repeat scroll;
  right: 20px;
  width: 26px;
  height: 26px;
  content: " ";
}

.faq details[open] .faq-content {
  border-top: 0px solid #e3e3e3;
}

.page-text .faq__content {
  border-left: 1px solid #e3e3e3;
  border-right: 1px solid #e3e3e3;
  border-bottom: 1px solid #e3e3e3;
  border-top: 0px solid #e3e3e3;
  padding: 0 40px 10px 15px;
}

.page-text .faq__content p {
  margin: 0;
  color: #707071;
}

.faq .button {
  background-color: #00a6ff;
  color: #fff;
  padding: 16px 32px;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  margin: 1em auto;
  cursor: pointer;
  display: inline-block;
  border: 1px;
  text-decoration: none;
  border-radius: 0;
}
/* FAQ */

.trust-casinno-icons-row {
  justify-content: center;
}

.trust-casinno-icons-row .col {
  flex-basis: 30%;
  max-width: 30%;
  margin-bottom: 20px;
  padding: 0 40px;
  box-sizing: border-box;
}

.casino-games-row {
  padding: 0 50px;
  justify-content: space-evenly;
  text-align: center;
}

.casino-games-row .col {
  max-width: 300px;
}

@media (max-width: 800px) {
  .row {
    flex-direction: column;
  }
  .bg-white {
    margin: 1em 0;
  }
  .page-text .button {
    width: auto;
    padding: 12px 30px;
    text-align: center;
    font-size: 18px;
    border-radius: 9px;
  }
  .mb-20 {
    margin-bottom: 15px;
  }
  .mt-20 {
    margin-top: 15px;
  }
  .mb-30 {
    margin-bottom: 20px;
  }
  .mt-30 {
    margin-top: 20px;
  }
  .mb-40 {
    margin-bottom: 25px;
  }
  .mt-40 {
    margin-top: 25px;
  }
  .mb-50 {
    margin-bottom: 30px;
  }
  .mt-50 {
    margin-top: 30px;
  }
  .trust-casinno-icons-row .col {
    flex-basis: 100%;
    max-width: 100%;
    padding: 0;
  }
  .faq details summary {
    padding: 18px 30px 18px 7px;
  }
  .faq details summary::after,
  .faq details[open] summary::after {
    right: 10px;
    width: 20px;
    height: 20px;
    background-size: cover;
    top: 50%;
    transform: translateY(-50%);
  }
  .page-text .faq__content {
    padding: 0 15px 10px;
  }
}

@media (min-width: 801px) {
  .page-text h1,
  .page-text h2,
  .page-text h3,
  .page-text h4,
  .page-text h5,
  .page-text h6 {
    font-size: 36px;
  }
}

@keyframes sweep {
  0% {
    opacity: 0;
    margin-top: -10px;
  }
  100% {
    opacity: 1;
    margin-top: 0px;
  }
}
</style>